import React, { Component } from 'react';
import Template from './Components/Pages/Template.js';
import { Router, Route, Link, Switch, Redirect, withRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import Cookies from 'universal-cookie';
import './App.css';
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const font = "'Lato', sans-serif";
//f6f7f8
const theme = createMuiTheme({
  fontFamily: font,
  palette: {
    background: {
      default: 'rgb(94,44,237)'
    },
    primary: {
      main: '#544cf8',
      green: '#ffffff'
    },
    secondary: {
      main: '#544cf8',
    },
    green: {
      main: 'rgb(120,195,17)',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        '&$disabled': {
          color: 'white',
          opacity: 0.4
        },
      },
    },
  },
});

class App extends Component {

  state = {
    present: false
  }

  componentDidMount() {
  }

  generateFooter = () => {
    return (
      <div style={{width: '100%', bottom: 0, backgroundColor: 'rgb(255,255,255)', height: 120}}>
        <div style={{width: '100%', height: 1, backgroundColor: 'rgb(240,240,240)'}}></div>
        <div style={{color: 'black', marginTop: 35}}>© 2019 WeSnapp. All rights reserved.</div>
        <div style={{color: 'black', marginTop: 12}}>
        <b><span><a style={{color: 'rgb(135,142,225)'}} target="_blank" href="https://wesnapp.co.il/Terms">Home</a></span></b>
        <b><span><a style={{color: 'rgb(135,142,225)', marginLeft: 15}} target="_blank" href="https://wesnapp.co.il/Terms">Terms & Conditions</a></span></b>
        </div>
      </div>
    )
  };

  render() {
    const cookies = new Cookies();
    return (
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Router history={history}>
            <Switch>
              <Route path="/request/:id" component={Template}/>
            </Switch>
          </Router>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;
