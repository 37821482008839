import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import { Box, Button, Checkbox, CircularProgress, Container, IconButton } from '@material-ui/core';

const vIcon = require("../../vIcon.png");

const useStyle = makeStyles((theme) => ({
  imageInRowContainer: {
    display: 'flex',
    width: '100%',
    height: 340 * (9/16),
    boxShadow: '0px 4px 10px #00000020',
    borderRadius: 8,
    background: 'white',
    position: 'relative'
  },
  imageInColumnContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    height: 72,
    width: '100%',
    paddingTop: 8,
    paddingBottom: 8, paddingLeft: 8,
    marginBottom: 16,
    boxShadow: '0px 4px 10px #00000020',
    background: 'white',
    position: 'relative'
  },
  imageInRow: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0, left: 0,
    borderRadius: 8,
    objectFit: 'cover',
    cursor: 'pointer'
  },
  imageInColumn: {
    height: 72 - 16,
    width: 72 - 16,
    borderRadius: 8,
    objectFit: 'cover',
    cursor: 'pointer'
  }
}))

const Image = (props) => {

  const classes = useStyle()

  const handleChange = (event) => {
    if (event.target.checked) {
      props.setSelectedImages(
        [
          ...props.selectedImages,
          props.image
        ]
      )
    } else {
      let newSelectedImages = []
      for (let index = 0; index < props.selectedImages.length; index++) {
        const selectedImage = props.selectedImages[index];
        if (selectedImage === props.image) continue
        newSelectedImages.push(selectedImage)
      }
      props.setSelectedImages(newSelectedImages)
    }
  }

  return ( 
    <div className={classes.imageInColumnContainer}>
      <div style={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
        {props.item.url != "" ? (
          <img alt='' src={props.item.url} className={classes.imageInColumn} />
        ) : (
          <img alt='' src={props.item.data} className={classes.imageInColumn} />
        )}
        <div style={{marginLeft: 18, display: 'flex'}}>
          Photo #{props.index}
          {props.item.percentCompleted < 100 && (
            <div style={{marginLeft: 18}}>
              - {props.item.percentCompleted}%
            </div>
          )}
        </div>
      </div>
      <div>
        {props.item.percentCompleted === 100 && (
          <div style={{paddingRight: 8}}>
            <img style={{width: 18, height: 18, borderRadius: 9, backgroundColor: 'rgb(120,203,117)', marginTop: 10}} src={vIcon} alt="..." />
          </div>
        )}
        {props.item.percentCompleted < 100 && (
          <div style={{paddingRight: 8}}>
            <CircularProgress size={20} variant="static" value={props.item.percentCompleted}/>
          </div>
        )}
      </div>
    </div>
  );
}
 
export default Image;