import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import Original from './Images/Original/Original';
import AT from './Images/AT/AT';
import Edited from './Images/Edited/Edited';
import Deleted from './Images/Deleted/Deleted';
import axios from 'axios';
import PhotoShootDetails from './PhotoShootDetails';
import PhotoShootDetailsSkeleton from './PhotoShootDetailsSkeleton';

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'red'
  }
});

class Template extends React.Component {

  state = {
    deviceType: 'desktop',
    uploadAndLastUpload: 'Upload',
    page: 'AT',
    data: null
  }

  componentDidMount() {
    // var page = this.getParameterByName('page')
    // this.setState({page: page})
    console.log(this.props.main.props.match.params.id)
    this.getRequestData()
  }

  getRequestData = async () => {
    var data = {id: this.props.main.props.match.params.id}
    const response = await axios.post("https://us-central1-wesnapp-d136a.cloudfunctions.net/getRequestFullData", data);
    this.setState({data: response.data})
  }

  getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div style={{width: 'calc(100vw - 120px)', paddingBottom: 120, minHeight: '100vh', backgroundColor: '#F9F8FD', display: 'inline-block', borderRadius: '35px 0px 0px 35px', verticalAlign:'top', overflowY: 'scroll', height: '100vh', position: 'sticky', position: '-webkit-sticky', top: 0, position: 'absolute', top: 0, left: '120px'}}>
        <Container maxWidth="xl" style={{marginTop: 18}}>
          {(this.state.data && this.state.data.request) ? (
            <PhotoShootDetails
              name={this.state.data.request.Name}
              photographer={this.state.data.request.Photographer_Name__c}
              dateTime={this.formatDate(this.state.data.request.Shooting_Date_Time__c)}
              product={this.state.data.request.Public_Product_Name__c}
              location={this.state.data.request.ShootingStreet__c + ', ' + this.state.data.request.ShootingCity__c}
              />
          ) : (
            <PhotoShootDetailsSkeleton />
          )}
        </Container>
        <>
          {/* {this.state.data && this.state.data.request && ( */}
            {/* <> */}
              {this.props.main.state.page === 'originals' && (
                <Original request={(this.state.data && this.state.data.request) ? this.state.data.request : null} getRequestData={this.getRequestData} />
              )}
              {this.props.main.state.page === 'at' && (
                <AT />
              )}
              {this.props.main.state.page === 'edited' && (
                <Edited request={(this.state.data && this.state.data.request) ? this.state.data.request : null} getRequestData={this.getRequestData} />
              )}
              {this.props.main.state.page === 'deleted' && (
                <Deleted request={(this.state.data && this.state.data.request) ? this.state.data.request : null} getRequestData={this.getRequestData} />
              )}
            {/* </> */}
          {/* )} */}
        </>
      </div>
    );
  }
}

Template.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withStyles(styles, { withTheme: true })(Template);
