import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import { Backdrop, Box, Button, Container, Grid, IconButton } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import AppsIcon from '@material-ui/icons/Apps';
import { CircularProgress, Divider } from 'material-ui';
import Image from '../Original/Image';
import axios from 'axios';
import DownloadDialog from '../DownloadDialog';
import Lightbox from 'react-image-lightbox';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import 'react-image-lightbox/style.css';
import UploadBox from '../UploadBoxEdited';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteDialog from '../DeleteDialog';
// import MenuItem from '@material-ui/core/MenuItem';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

const GET_BRACKETS_ORGENIZED_IMAGES = 'https://py.wesnapp.co.il/server/get-images-orgenized/'
const GET_ANGEL_IMAGES = 'https://py.wesnapp.co.il/server/get-angel-images/';
const GET_EDITED_ORGENIZED_IMAGES = 'https://py.wesnapp.co.il/server/get-edited-images-orgenized/'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyle = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    // width: '100%',
    paddingTop: 24,
    position: 'sticky',
    top: 0,
    zIndex: 999,
    background: '#F9F8FD',
    paddingBottom: 24,
    alignItems: 'center'
  },
  toolbarSelection: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    // width: '100%',
    paddingTop: 24,
    position: 'sticky',
    top: 80,
    height: 80,
    zIndex: 999,
    background: '#F9F8FD',
    paddingBottom: 24,
    alignItems: 'center'
  },
  type: {
    fontSize: 26,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  toolbarRightSide: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center'
  },
  toolbarRightSideIconButton: {
    marginTop: 4,
    marginLeft: 4
  },
  button: {
    background: theme.palette.primary.main,
    color: 'white',
    textTransform: 'none',
    paddingLeft: 24, paddingRight: 24,
    borderRadius: 12,
    boxShadow: `0px 6px 10px ${theme.palette.primary.main}60`,
    height: 42,
    fontWeight: 'bold',
    '&:hover': {
      background: theme.palette.primary.main,
    }
  },
  exposureButton: {
    background: theme.palette.primary.main,
    color: 'white',
    textTransform: 'none',
    paddingLeft: 24, paddingRight: 24,
    borderRadius: 12,
    boxShadow: `0px 6px 10px ${theme.palette.primary.main}60`,
    height: 42,
    maxWidth: 200,
    marginTop: 16,
    fontWeight: 'bold',
    '&:hover': {
      background: theme.palette.primary.main,
    }
  },
  deleteButton: {
    background: '#eb4034',
    color: 'white',
    textTransform: 'none',
    paddingLeft: 24, paddingRight: 24,
    borderRadius: 12,
    boxShadow: `0px 6px 10px #eb403460`,
    height: 42,
    fontWeight: 'bold',
    '&:hover': {
      background: '#eb4034',
    }
  },
  downloadButton: {
    background: '#34eb7d',
    color: 'white',
    textTransform: 'none',
    paddingLeft: 24, paddingRight: 24,
    borderRadius: 12,
    boxShadow: `0px 6px 10px #34eb7d60`,
    height: 42,
    fontWeight: 'bold',
    '&:hover': {
      background: '#34eb7d',
    }
  },
  selectedView: {
    color: theme.palette.primary.main
  },
  imagesContainerRow: {
    marginTop: 32,
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'wrap',
    gap: 12,
    justifyContent: 'space-between'
  },
  imagesContainerColumn: {
    marginTop: 32,
    display: 'flex',
    flexFlow: 'column',
    gap: 12
  },
  imagesGridContainer: {
    marginTop: 32
  }
}))

const Original = (props) => {

  const classes = useStyle()

  let { id } = useParams();

  const [images, setImages] = useState({})
  const [view, setView] = useState('row')
  const [galleryOpen, setGalleryOpen] = useState(false)
  const [galleryIndex, setGalleryIndex] = useState(0)
  const [selectionOn, setSelectionOn] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [foundImages, setFoundImages] = useState([])

  // Download
  const [downloadPercenteges, setDownloadPercenteges] = useState(0)
  const [prepearingImages, setPrepearingImages] = useState(false)
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false)
  const [loading, setLoading] = useState(true)

  // Delete
  const [deleting, setDeleting] = useState(false)

  // Alert
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertType, setAlertType] = useState('success')

  const [uploadIsOpen, setUploadIsOpen] = useState(false)

  const [openDeleteDialog, setOpenDeleteDialog] = useState(null)

  const [isLowerCase, setIsLowerCase] = useState(true)


  const handleCloseAlert = () => {
    setShowAlert(false)
    setAlertMessage('')
  }

  const showAlertMessage = (type, message) => {
    setAlertMessage(message)
    setAlertType(type)
    setShowAlert(true)
  }

  useEffect(() => {
    if (!loading) {
      if (!foundImages) {
        setUploadIsOpen(true)
      }
    }
  }, [loading])

  useEffect(() => {
    if (uploadIsOpen) setSelectionOn(false)
  }, [uploadIsOpen])

  useEffect(() => {
    if (selectionOn) setUploadIsOpen(false)
  }, [selectionOn])

  useEffect(() => {
    console.log(props)
    fetchImages()
  }, [])

  const fetchImages = async () => {
    let newImages = await getEditedImages()
    setImages(newImages)
    if (newImages.images.thumbnail.length > 0) {
      let surffix = newImages.images.thumbnail[0].split('.')[newImages.images.thumbnail[0].split('.').length - 1]
      if (surffix.toUpperCase() === surffix) {
        setIsLowerCase(false)
      }
    }
    setLoading(false)
  }

  const uploadDownloadProgress = () => {
    return (progress) => {
      var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
      setDownloadPercenteges(percentCompleted)
      setPrepearingImages(false)
    }
  }

  const getDownloadUrl = (selectedImage) => {
    return selectedImage.replace('https://storage.googleapis.com/wesnapp/', '').replace('/compressed','').replace('_reduced','')
  }

  const downloadImages = () => {
    let zipname = `Wesnapp_${'AT'}`
    if (downloadPercenteges == 0) {
      var lineOfFiles = ''
      for (var i = 0; i < selectedImages.length; i++) {
        let path = getDownloadUrl(selectedImages[i])
        if (lineOfFiles == '') {
          lineOfFiles = path
        } else {
          lineOfFiles = lineOfFiles + ',' + path
        }
      }
      setDownloadPercenteges(1)
      setPrepearingImages(true)
      var config = {
        responseType: 'blob',
        onDownloadProgress: uploadDownloadProgress()
      };
      var filesOld = lineOfFiles.split(',')
      var files = []
      var folder = filesOld[0].split("/" + filesOld[0].split('/')[filesOld[0].split('/').length - 1])[0]
      for (var j = 0; j < filesOld.length; j++) {
        files.push(filesOld[j].split(folder + '/')[1])
      }
      setOpenDownloadDialog(true)
      axios.post('https://py.wesnapp.co.il/server/zipfiles-images',{folder: folder, files: files, zipname: zipname}, config).then((response) => {
        var headers = response.headers;
        var blob = new Blob([response.data],{type:headers['content-type']});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = zipname;
        link.click();
        link.remove()
        setDownloadPercenteges(0)
        setOpenDownloadDialog(false)
        setSelectedImages([])
        setSelectionOn(false)
        showAlertMessage('success', 'Images successfully downloaded')
      })
      .catch( (error) => {
        console.log(error);
      });
    }
  }

  const deleteImages = async () => {
    setDeleting(true)
    let promiseDelete = []
    for (var i = 0; i < selectedImages.length; i++) {
      let path = getDownloadUrl(selectedImages[i])
      promiseDelete.push(axios.post("https://py.wesnapp.co.il/delete-file" ,{path: path}, {headers: {'Content-Type': 'application/json'}}))
    }
    await Promise.all(promiseDelete)
    let newImagesFromServer = await getEditedImages()
    setImages(newImagesFromServer)
    setDeleting(false)
    setSelectedImages([])
    setSelectionOn(false)
    showAlertMessage('success', 'Images successfully deleted')
  }

  const getNewImagesAfterUpload = async () => {
    setDeleting(true)
    let newImages =  await getEditedImages()
    setImages(newImages)
    setDeleting(false)
    setSelectedImages([])
    setSelectionOn(false)
    setUploadIsOpen(false)
  }

  const getEditedImages = async () => {
    const response = await axios.get(GET_EDITED_ORGENIZED_IMAGES + id + '-edited',{}, {headers: {'Content-Type': 'application/json'}});
    const gallery = []
    console.log('response', response )
    for (var i = 0; i < response.data.originals.length; i++) {
      gallery.push({
        src: response.data.originals[i],
        thumbnail: response.data.thumbnail[i],
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        caption: "Image " + String(i)
      })
    }
    const newImages = {
      images: response.data,
      gallery: gallery
    }
    return newImages
  }

  const openGallery = (index) => {
    setGalleryIndex(index)
    setGalleryOpen(true)
  }

  const canSelectAll = () => {
    if (images && images.images && images.images.originals) {
      if (images.images.originals.length === selectedImages.length) return false
        return true
    }
    return false
  }

  const selectAll = () => {
    setSelectedImages(images.images.originals)
  }

  const diselectAll = () => {
    setSelectedImages([])
  }

  const getGridSizeBaseOnExposures = () => {
    let items = 1
    items = 4
    let spaces = ((items - 1) * 12)
    return {width: `calc((100% - ${String(spaces)}px) / ${String(items)})`}
  }

  return ( 
    <Container maxWidth="xl">
      <DeleteDialog open={openDeleteDialog} setOpen={setOpenDeleteDialog} handleDelete={deleteImages} />
      <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Backdrop open={deleting || loading} style={{zIndex: 999999}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <DownloadDialog open={openDownloadDialog} setOpen={setOpenDownloadDialog} downloadPercenteges={downloadPercenteges} />
      {galleryOpen && (
        <Lightbox
          mainSrc={images.images.compressed[galleryIndex]}
          nextSrc={images.images.compressed[(galleryIndex + 1) % images.images.compressed.length]}
          prevSrc={images.images.compressed[(galleryIndex + images.images.compressed.length - 1) % images.images.compressed.length]}
          onCloseRequest={() => setGalleryOpen(false)}
          onMovePrevRequest={() =>
            setGalleryIndex((galleryIndex + images.images.compressed.length - 1) % images.images.compressed.length)
          }
          onMoveNextRequest={() =>
            setGalleryIndex((galleryIndex + 1) % images.images.compressed.length)
          }
        />
      )}
      <div className={classes.toolbar}>
        <div className={classes.toolbarRightSide}>
          <div className={classes.type}>
            Edited Images ({(images && images.images && images.images.originals) ? images.images.originals.length : 0})
          </div>
          <IconButton onClick={()=> setView('column')} className={classes.toolbarRightSideIconButton}>
            {view === 'column' ? <ViewListIcon className={classes.selectedView} /> : <ViewListIcon />}
          </IconButton>
          <IconButton onClick={()=> setView('row')} className={classes.toolbarRightSideIconButton}>
            {view === 'row' ? <AppsIcon className={classes.selectedView} /> : <AppsIcon />}
          </IconButton>
        </div>
        <div>
          <Button onClick={()=> setSelectionOn(true)} className={classes.button} style={{opacity: selectionOn ? 0.5 : 1, marginRight: 12}} disabled={selectionOn}>
            Select
          </Button>
          {!uploadIsOpen ? (
            <Button onClick={() => setUploadIsOpen(true)} className={classes.button}>
              + Add Photos
            </Button>
          ) : (
            <Button onClick={()=> setUploadIsOpen(false)} className={classes.button}>
              Cancel Upload
            </Button>
          )}
        </div>
      </div>
      <Divider />
      {selectionOn && (
        <div className={classes.toolbarSelection} style={{paddingBottom: 0}}>
          <div className={classes.toolbarRightSide}>
            <Button onClick={()=> setSelectionOn(false)} className={classes.button} disabled={!selectionOn}>
              Cancel Selection
            </Button>
            <Button onClick={selectAll} className={classes.button} style={{marginLeft: 12}} disabled={!canSelectAll()}>
              Select All
            </Button>
            <Button onClick={diselectAll} className={classes.button} style={{marginLeft: 12}} disabled={selectedImages.length === 0}>
              Diselect All
            </Button>
          </div>
          <div>
            <Button onClick={()=> downloadImages()} className={classes.downloadButton} disabled={selectedImages.length === 0} style={{marginRight: 12}}>
              Download ({selectedImages.length})
            </Button>
            <Button onClick={()=> setOpenDeleteDialog(true)} className={classes.deleteButton} disabled={selectedImages.length === 0}>
              Delete ({selectedImages.length})
            </Button>
          </div>
        </div>
      )}
      {(uploadIsOpen) && (
        <UploadBox request={props.request} getRequestData={props.getRequestData} images={images.images.originals} showAlertMessage={showAlertMessage} exposure={'edited'} requestId={id} getNewImagesAfterUpload={getNewImagesAfterUpload} />
      )}
      {(images && images.images && images.images.originals) && (
        <div justify={'flex-start'} className={classes.imagesGridContainer} style={{display: 'flex', gap: 12, flexWrap: 'wrap', }}>
          {images.images.originals.map((image, index) => {
            if (view === 'column') {
              return (
                <div style={{width: '100%'}}>
                  <Image isLowerCase={isLowerCase}selectionOn={selectionOn} setSelectedImages={setSelectedImages} selectedImages={selectedImages} openGallery={openGallery} key={index} index={index} image={image} view={view} />
                </div>
              )
            } else {
              return (
                <div style={getGridSizeBaseOnExposures()}>
                  <Image isLowerCase={isLowerCase}selectionOn={selectionOn} setSelectedImages={setSelectedImages} selectedImages={selectedImages} openGallery={openGallery} key={index} index={index} image={image} view={view} />
                </div>
              )
            }
          })}
        </div>
      )}
    </Container>
  );
}
 
export default Original;